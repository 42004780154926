const messages = {
  hr: {
    app: {
      about: 'O aplikaciji',
      actions: {
        add: 'Dodati',
        addNamed: 'Dodati {name}',
        remove: 'Poništiti',
        removeNamed: 'Poništiti {name}',
        removeConfirmation: 'Zaista želite poništiti?',
        more: 'Više',
        less: 'Manje',
        download: 'Skinuti',
        save: 'Pohraniti',
        cancel: 'Ukinuti',
        close: 'Zatvoriti',
        moreFormFields: 'Više polja',
        export: 'Eksportirati',
        edit: 'Urediti',
        createdSuccessMessage: 'Uspješno kreirano.',
        savedSuccessMessage: 'Uspješno pohranjeno.',
        deletedSuccessMessage: 'Uspješno poništeno',
        detail: 'Detalj',
        next: 'Dalje',
        back: 'Natrag',
        start: 'Start',
        whatNext: 'Što dalje...',
        confirm: 'Potvrda',
        confirmRemove: 'Zaista želite poništiti?',
        search: 'Tražiti',
        userProfile: 'Korisnički profil',
        tenantProfile: '',
        imports: 'Importi',
        fileImports: 'Import datoteka',
        settings: 'Postavke',
        logout: 'Odjava',
        login: 'Prijava',
        filterList: 'Filtrirati listu',
        excel: 'Excel',
        refresh: 'Obnoviti',
        clear: 'Očistiti',
        agree: 'Slažem se',
        disagree: 'Ne slažem se',
        makeCopy: 'Napraviti kopiju',
        fullscreen: 'Prikaži preko cijelog ekrana',
        fullscreenClose: 'Ukinuti režim prikazivanja preko cijelog ekrana',
        copy: 'Kopirati',
      },
      messages: {
        confirmRemove: 'Zaista želite ukloniti ovaj artikl?',
        errorOccurred: 'Došlo je do pogreške',
        createdSuccess: 'Kreiranje je bilo uspješno.',
        savedSuccess: 'Pohranjivanje je bilo uspješno',
        deletedSuccess: 'Poništavanje je bilo uspješno.',
        uploadSuccess: 'Datoteka učitana uspješno.',
        uploadError: 'Pogreška prilikom učitavanja datoteke.',
        uploadInProgress: 'Učitava se...',
        uploadedFiles:
          '{number, plural, one {1 datoteka je bila učitana} few {{number} datoteke su bile učitane} other {{number} datoteka je bilo učitanih}}',
      },
      error: 'Pogreška!',
      validations: {
        isRequired: 'Polje {field} je obvezno.',
        minLengthIsRequired:
          'Potreban je minimalno {number, plural, one {1 artikl} few {{number} artikla} other {{number} artikala}}.',
        minValue: 'Minimalna dozvoljena vrijednost je {value}.',
        maxValue: ' Maksimalna dozvoljena vrijednost je {value}.',
        passwordMatchError: 'Lozinke se ne slažu',
        invalidEmail: 'Nevažeći e-mail.',
        invalidPhoneNumber: 'Nevažeći broj telefona. Unesite broj u međunarodnom formatu "+X XXX XXX XXX".',
        notValidNumber: 'Nevažeći broj',
        invalidUrl: 'Nevažeća URL adresa.',
        positive: 'Broj mora biti pozitivan.',
        negative: 'Broj mora biti negativan.',
      },
      login: {
        title: '',
        username: 'Korisničko ime',
        password: 'Lozinka',
        loginButton: 'Prijaviti se',
        error: 'Prijava nije bila uspješna. Korisničko ime ili lozinka nisu bili pronađeni.',
        pleaseRelogin: 'Izgleda da niste prijavljeni. Molimo prijavite se ponovno.',
      },
      days: '{count, plural, one {1 dan} few {{formattedCount} dana} other {{formattedCount} dana}}',
      overdue: 'zakašnjenje {count, plural, one {1 dan} few {{formattedCount} dana} other {{formattedCount} dana}}',
      newItem: 'Novi',
      anonymizing: {
        confirm: {
          title: '',
          content: '',
        },
        button: '',
      },
      appMenu: {
        discover: '',
      },
      missingType: '',
      missingTypeForEntityType: '',
      emptyScoringBlog: '',
    },
    components: {
      buttons: {
        showOnMap: '',
      },
      filters: {
        filterButtonSubmit: 'Tražiti',
        filterFieldName: '{name} ({conditionType})',
        addCondition: 'Dodati uvjet',
        resetFilter: 'Resetirati filtar',
        operators: {
          contains: {
            0: 'sadrži',
            _: 'sadrži',
          },
          containsexact: {
            0: 'sadrži točno',
            _: 'sadrži točno',
          },
          notcontains: {
            0: 'ne sadrži',
            _: 'ne sadrži',
          },
          betweennumber: {
            0: 'od',
            1: 'do',
            _: 'među',
          },
          betweendate: {
            0: 'od',
            1: 'do',
            _: 'među',
          },
          equal: {
            0: 'jednako je',
            _: 'jednako je',
          },
        },
      },
      paginationList: {
        itemsTotal: 'Artikala ukupno: {number}',
        nextPage: 'Učitati {number} artikala',
        emptyMessage: 'Artikli nisu bili pronađeni.',
      },
      selects: {
        emptyOption: 'Nikakva',
        emptyMessage: 'Artikli nisu bili pronađeni.',
      },
      tables: {
        labelRowsPerPage: 'Redaka na stranici',
        sortBy: 'Poredati prema {field}',
        recordsChanged: 'Neki su zapisi bili dodani ili poništeni.',
      },
      textField: {
        errorMsgWrapper: 'Pogreška: {msg}',
      },
      dateField: {
        invalidDateFormat: 'Pogrešan format podatka',
        invalidDate: 'Nepoznat',
        maxDateMessage: 'Datum ne smije biti veći nego maksimalni datum',
        minDateMessage: ' Datum ne smije biti veći nego minimalni datum ',
        today: 'Danas',
      },
      detailNavigation: {
        scoringblog: 'Scoring Blog',
        card: 'Karta',
        relations: 'Relacija',
      },
      manipulationInfo: {
        created: 'Kreirano',
        updated: 'Uređeno',
        removed: 'Poništeno',
      },
    },
  },
};

export default messages;
