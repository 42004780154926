const messages = {
  hr: {
    shoppingCart: {
      actions: {
        addToCart: 'Dodati u košaricu',
        buy: 'U košaricu',
        order: 'Poručiti',
        remove: 'Ukloniti',
      },
      cart: {
        total: {
          label: 'Ukupno',
          helperText: '',
          helperTextPreview: '',
        },
        totalItems: {
          label: 'Ukupno za retke',
          helperText: '',
          helperTextPreview: '',
        },
        totalShipping: {
          label: 'Ukupno za dostavu',
          helperText: '',
          helperTextPreview: '',
        },
        totalPayment: {
          label: 'Ukupno za plaćanje',
          helperText: '',
          helperTextPreview: '',
        },
        totalTaxes: {
          label: 'Porez ukupno',
          helperText: '',
          helperTextPreview: '',
        },
        resetCart: 'Resetirati košaricu',
      },
      messages: {
        confirmResetCart: 'Jeste li sigurni da želite poništiti sadržaj košarice?',
        giftClaim: 'Možete dobiti {quantity} komada {productName} kao poklon.',
        cartManipulations: {
          allRemoved: 'Svi artikli u košarici su bili uklonjeni.',
          itemAdded: 'U košaricu {number, plural, one {bio je dodan artikl} other {bili su dodani artikli}}.',
          itemRemoved: 'Iz košarice {number, plural, one {bio je uklonjen artikl} other {bili su uklonjeni artikli}}.',
          quantityChaged: 'Promijenila se kupljena količina.',
        },
      },
      components: {
        appBartActionCart: 'Košarica',
        quantityField: {
          states: {
            quantityRounded: 'Količina je bila zaokružena na najbližu veličinu pakiranja..',
          },
        },
        cartAsidePreview: {
          cartEmpty: 'Vaša je košarica prazna',
        },
        cartAfterAddModal: {
          continueShopping: 'Nastaviti u kupovini',
          goToCart: 'Preći u košaricu',
          title: 'Proizvodi su bili dodani u košaricu',
          pcs: 'kom',
        },
      },
    },
  },
};

export default messages;
