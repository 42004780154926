const messages = {
  hr: {
    com: {
      components: {
        headerCartButton: {
          cartButton: 'Košarica',
        },
      },
      productDetail: {
        parameters: {
          title: 'Parametri',
        },
      },
      productAvailability: {
        format: {
          available: 'Na lageru',
          moreThanX: 'Više od {min} komada na lageru',
          exactCount: '{value} komada na lageru',
          notAvailable: 'Nije dostupno',
        },
      },
      imageGallery: {
        thumbnailsMore: '+ {number} više',
      },
      relatedProducts: {
        title: 'Povezani proizvodi',
      },
      productAccessories: {
        title: 'Prateća oprema',
      },
      productDetailImageGallery: {
        noImage: 'Bez slike',
      },
      productAvailabilityFieldPreviewWithPopup: {
        button: 'Kada ću dobiti robu?',
      },
    },
  },
};

export default messages;
