const messages = {
  hr: {
    orders: {
      actions: {
        addNewOrder: 'Dodati narudžbu',
        all: 'Sve narudžbe',
      },
      listPage: {
        title: 'Narudžbe',
        table: {
          filter: {
            number: 'Broj',
          },
          columns: {
            contact: 'Kontakt',
            dateOfOrder: 'Datum narudžbe',
            number: 'Broj',
            offer: 'Ponuda',
            opportunity: 'Prilika',
            owner: 'Vlasnik',
            state: 'Stanje',
            total: 'Ukupno',
            totalUserCurrency: 'Ukupno u {currency}',
          },
        },
      },
      order: {
        newEntity: 'Nova narudžba',
        contact: {
          label: 'Kontakt',
          helperText: '',
          helperTextPreview: '',
        },
        commissionTotal: {
          label: 'Cjelokupna provizija',
          helperText: '',
          helperTextPreview: '',
        },
        currency: {
          label: 'Valuta',
          helperText: '',
          helperTextPreview: '',
        },
        dateOfOrder: {
          label: 'Datum narudžbe',
          helperText: '',
          helperTextPreview: '',
        },
        number: {
          label: 'Broj',
          helperText: 'Za automatsko generiranje broja ostavite nepopunjeno.',
          helperTextPreview: '',
        },
        offer: {
          label: 'Ponuda',
          helperText: '',
          helperTextPreview: '',
        },
        opportunity: {
          label: 'Prilika',
          helperText: '',
          helperTextPreview: '',
        },
        owner: {
          label: 'Vlasnik',
          helperText: '',
          helperTextPreview: '',
        },
        salesMargin: {
          label: 'Trgovačka marža',
          helperText: '',
          helperTextPreview: '',
        },
        subjects: {
          title: 'Predmet',
          discount: {
            label: 'Popust',
            helperText: '',
            helperTextPreview: '',
          },
          commission: {
            label: 'Provizija',
            helperText: '',
            helperTextPreview: '',
          },
          list: {
            package: 'Veličina pakiranja',
          },
          listPrice: {
            label: 'Cijena s cjenika',
            helperText: '',
            helperTextPreview: '',
          },
          price: {
            label: 'Prodajna cijena ',
            helperText: '',
            helperTextPreview: '',
          },
          product: {
            label: 'Proizvod',
            helperText: '',
            helperTextPreview: '',
          },
          quantity: {
            label: 'Količina',
            helperText: '',
            helperTextPreview: '',
          },
          salesMargin: {
            label: 'Trgovačka marža',
            helperText: '',
            helperTextPreview: '',
          },
          subjectTotal: {
            label: 'Ukupno za artikl',
            helperText: '',
            helperTextPreview: '',
          },
          unit: {
            label: 'Jedinica',
            helperText: '',
            helperTextPreview: '',
          },
        },
        total: {
          label: 'Ukupno',
          helperText: '',
          helperTextPreview: '',
        },
        numberOfSubjects:
          '{number, plural, =0 {nikakvi artikli} one {1 artikl} few {{number} artikla} other {{number} artikala}}',
      },
      myOrdersScoringBlog: {
        title: 'Moje narudžbe',
      },
      ordersList: 'Narudžbe',
    },
  },
};

export default messages;
