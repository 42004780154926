const messages = {
  hr: {
    reactMuiTables: {
      selected: 'odabrano',
      filterhide: 'Sakriti filtar',
      filtershow: 'Prikazati filtar',
      refresh: 'Obnoviti',
      filtar: {
        actions: {
          search: 'Tražiti',
          resetFilter: 'Resetirati filtar',
          addCondition: 'Dodati uvjet',
        },
      },
      standardOperators: {
        equals: 'točno',
        contains: 'sadrži',
        between: {
          label: 'među',
          from: 'od',
          to: 'do',
        },
        oneOf: 'jedan od',
      },
      labelRowsPerPage: 'Redaka na stranici:',
    },
  },
};

export default messages;
