// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleHr from '@sprinx/react-globalize/CldrLocaleHr';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
import React from 'react';
import MessagesHr from './i18n/MessagesHr';

// CLDRS
// const CldrLocaleCs = loadable(() => import('@sprinx/react-globalize/CldrLocaleCs'), { fallback: <ComponentLoader /> });

// Messages
// const MessagesCs = loadable(() => import('./MessagesCs'), { fallback: <ComponentLoader /> });

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  const defaultLocale = 'hr';
  const supportedLocales = ['hr'];

  return (
    <GlobalizeProvider
      cldrs={{ hr: CldrLocaleHr }}
      messages={{ hr: MessagesHr }}
      defaultLocale={defaultLocale || 'cs'}
      supportedLocales={supportedLocales || ['cs']}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
      dontResolveLocale
      // defferInitialization={onAuth}
      // cache={cache}
      // loadSetting={loadLanguageSetting}
      // onPreferredLanguageChanged={onPreferredLanguageChanged}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
