const messages = {
  hr: {
    sprinxFormsValidation: {
      required: 'Polje je obvezno!',
      string: 'Mora biti tekstualni niz!',
      stringEmpty: 'Ne smije biti prazno!',
      stringMin: 'Duljina mora biti veća ili jednaka duljini {expected} znakova!',
      stringMax: ' Duljina mora biti kraća ili jednaka duljini {expected} znakova!',
      stringLength: 'Duljina mora imati {expected} znakova!',
      stringPattern: 'Ne odgovara željenom uzoru!',
      stringContains: "Mora sadržavati tekst '{expected} '!",
      stringEnum: 'Ne odgovara nijednoj od dopuštenih vrijednosti!',
      stringNumeric: 'Mora biti broj!',
      number: 'Mora biti broj!',
      numberMin: 'Mora biti veći ili jednako {expected}!',
      numberMax: ' Mora biti manji ili jednako {expected}!',
      numberEqual: 'Mora biti jednako s {expected}!',
      numberNotEqual: 'Ne može biti jednako s {expected}!',
      numberInteger: 'Mora biti cijeli broj!',
      numberPositive: 'Mora biti pozitivan broj!',
      numberNegative: 'Mora biti negativan broj!',
      array: 'Mora biti lista!',
      arrayEmpty: 'Ne smije biti prazna lista!',
      arrayMin: 'Potreban je barem {number, plural, one {1 artikl} few {{number} artikla} other {{number} artikala}}!',
      arrayMax:
        'Ne može sadržavati više od {number, plural, one {1 artikla} few {{number} artikla} other {{number} artikala}}!',
      arrayLength:
        'Mora sadržavati točno {expected, plural, one {1 artikl} few {{number} artikla} other {{number} artikala}}!',
      arrayContains: "Mora sadržavati artikl '{expected}'!",
      arrayEnum: "Vrijednost '{expected}' ne odgovara nijednoj od dopuštenih vrijednosti!",
      boolean: "Mora biti 'da' ili 'ne'!",
      function: 'Mora biti funkcija!',
      date: 'Mora biti datum!',
      dateMin: 'Mora biti veći ili jednako {expected}!',
      dateMax: ' Mora biti manji ili jednako {expected}!',
      forbidden: 'Polje nije dopušteno!',
      email: 'Nevažeći e-mail!',
      url: 'Nevažeći URL!',
      object: 'Mora biti tipa Object!',
      arrayReducedEquals: 'Sažeta vrijednost ne odgovara {expected}!',
      phoneNumber: "Mora biti važeći telefonski broj međunarodnog formata '+ XXX XXX XXX XXX '!",
      dateGt: 'Datum mora biti veći od {expected}!',
      dateGte: 'Datum mora biti veći ili jednako {expected}!',
      dateLt: 'Datum mora biti manji od {expected}!',
      dateLte: ' Datum mora biti manji ili jednako {expected}!',
    },
  },
};

export default messages;
