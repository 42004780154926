const messagesHR = {
  hr: {
    parameters: {
      yes: 'Da',
    },
    com: {
      phase: {
        label: 'Oznaka',
      },
      product: {
        ean: {
          label: 'EAN',
          helperText: '',
          helperTextPreview: '',
        },
        sku: {
          label: 'SKU',
          helperText: '',
          helperTextPreview: '',
        },
        manufacturer: {
          label: 'Proizvođač',
          helperText: '',
          helperTextPreview: '',
        },
        pn: {
          label: 'Broj dijela',
          helperText: '',
          helperTextPreview: '',
        },
        warranty: {
          label: 'Jamstvo',
          months: 'Mjeseca',
        },
      },
      totalAmount: {
        label: 'Ukupno',
        helperText: '',
      },
    },
    imageGallery: {
      thumbnailsMore: '+ {number} ostalih',
    },
    orders: {
      actions: {
        all: 'Sve narudžbe',
      },
      myOrdersScoringBlog: {
        title: 'Moje narudžbe',
      },
      ordersList: 'Narudžbe',
      phase: {
        1: 'Primljeno',
        10: 'Razmatra se',
        20: 'Čeka se plaćanje',
        30: 'Plaćeno',
        98: 'Razvrstaj',
        99: 'Otkazano',
        '01-new': 'Primljeno',
      },
      listPage: {
        table: {
          columns: {
            packageId: 'Broj paketa',
            phase: 'Stanje',
            paid: 'Plaćeno',
            total: 'Bez PDV-a',
            totalWithTax: 'Ukupno',
          },
        },
      },
    },
    baskets: {
      cartSinglePage: {
        title: 'VAŠA KOŠARICA',
        buyButton: 'Pošalji narudžbu',
        emptyBasket: 'Vaša je košarica prazna',
        buyThere: 'Već sam ovdje kupovao/la',
        login: 'Želim se prijaviti',
        notFill: 'Pojedinosti o dostavi nisu ispunjene',
        fill: 'Ispunite podatke',
        change: 'Promjena podataka',
        popupButton: 'Prikaži na karti',
        payment: {
          title: 'PLAĆANJE',
          free: 'Besplatno',
          changePaymentBtn: 'PROMIJENI NAČIN PLAĆANJA',
        },
        shipping: {
          title: 'DOSTAVA',
          free: 'Besplatno',
          changeShippingBtn: 'PROMIJENI NAČIN DOSTAVE',
          popup: {
            title: 'Informacije',
            text: 'Dostava na adresu izravno dostavnom službom {service}',
          },
        },
      },
      actions: {
        all: 'Sve košarice',
      },
      myBasketsScoringBlog: {
        title: 'Spremljene košarice',
      },
      cartSummaryParameters: {
        caption: 'Šifra proizvoda',
      },
    },
    productDetailSpagettyNavigation: {
      description: 'Opis',
      parameters: 'Parametri',
      package: 'Sadržaj pakiranja',
      fotoAndVideo: 'Foto i video',
      alternatives: 'Slično',
      alternativesText: 'Nažalost, trenutno nemamo alternativu za proizvod koji ste odabrali.',
      questions: 'Pitanja',
      productDescriptionNotAvailable: 'Opis proizvoda nije dostupan',
    },
    newsletter: {
      phone: '+ 385 92 404 699 1',
      subscribe: {
        title: 'nemojte propustiti posebne ponude',
        inputText: 'Vaša adresa e-pošte',
        button: 'Prijavi se',
      },
    },
    globalNavigation: {
      home: 'Početna stranica',
      shopping: 'Na kupnju',
      orders: 'Narudžbe',
      shoppingLists: 'Popisi za kupnju',
      articles: 'Proizvodi',
    },
    homePageWhy: {
      0: {
        title: 'Kupujte sigurno',
        content: 'Članovi smo udruženja E-commerce i jamčimo sigurnu online kupnju.',
      },
      1: {
        title: 'Služba za korisnike',
        content: 'Zadovoljstvo kupaca za nas je na prvom mjestu, stoga smo uvijek tu za vas.',
      },
      2: {
        title: 'Roba na skladištu',
        content: 'Na skladištu imamo 98% proizvoda, odmah spremnih za slanje.',
      },
      3: {
        title: 'Besplatan prijevoz',
        content: 'S kupnjom odabranih digitalnih fotoaparata i leća dobivate BESPLATNU dostavu po Hrvatskoj.',
      },
      title: ' ZAŠTO KUPOVATI KOD NAS?',
    },
    startPage: {
      title: 'Početna stranica',
      gotoShopping: 'Na kupnju',
    },
    shoppingCart: {
      buyBtn: 'DODAJ U KOŠARICU',
      alternatives: 'Alternativa',

      shipping: {
        changePoint: 'Promijeni',
      },
      agreements: {
        termsRequired: 'Morate prihvatiti uvjete poslovanja.',
      },
      validation: {
        someProductNotAvailable: 'Nekih proizvoda u skladištu nema u dovoljnoj količini.',
      },
    },
    appCartSummaryProductParameters: {
      title: 'Šifra robe:',
    },
    productListPage: {
      title: 'Katalog',
      orders: {
        recommended: 'Preporučeno',
        name: 'Naziv',
        priceAsc: 'Prema cijeni uzlazno',
        priceDesc: 'Prema cijeni silazno',
      },
    },
    orderStatuses: {
      storno: 'Storno',
      synchronized: 'Poslano',
      waiting: 'Čeka se na sinkronizaciju',
    },
    orderPreview: {
      items: 'Artikli',
    },
    cartRegisterForm: {
      title: 'Obrazac za registraciju',
    },
    cartPayment: {
      title: 'Plaćanje',
    },
    cartPreSummaryPage: {
      title: 'Sažetak košarice',
      actions: {
        nextStep: 'Slažem se i nastavi',
      },
    },
    cartCompanyForm: {
      description: 'Ispunite ako robu kupujete na tvrtku .',
    },
    cartDeliveryForm: {
      description: 'Ispunite ako se adresa za fakturu razlikuje od adrese za dostavu.',
    },
    productAvailabilityFieldPreviewWithPopup: {
      button: 'Kada ću dobiti robu?',
    },
    productDetailStockInfo: {
      available: 'Roba se nalazi u našem distribucijskom skladištu',
      external: 'Proizvod je na putu do našeg skladišta',
      notAvailable: 'Roba trenutno nije dostupna',
      labels: {
        free: 'BESPLATNA DOSTAVA',
        showroom: 'IZLOŽENO U SHOWROOMU',
      },
    },
    productGifts: {
      btnName: 'POKLON',
      giftValue: 'U vrijednosti',
    },
    homepage: {
      productOfMonth: 'Proizvodi mjeseca',
      catalogFavorite: 'Omiljene kategorije',
      hotTips: 'Ekskluzivna ponuda',
      recommended: 'Preporučeno',
    },
    productQuestion: {
      firstName: 'Ime',
      lastName: 'Prezime',
      email: 'E-pošta',
      subject: 'Predmet',
      question: 'Upit',
      gdpr: 'Slanjem upita primam na znanje obradu osobnih podataka u svrhu odgovora na upit.',
      gdprLink: 'Veza na GDPR',
      button: 'Pošalji upit',
      subjectContent: 'Upit o proizvodu: {product}, šifra: {sku}',
      title: 'Morate prihvatiti obradu osobnih podataka.',
      success: 'Uspješno poslano',
    },
    lastVisitedProducts: 'Posljednji pogledani proizvodi',
    productAlternativeProducts: 'Alternative',
    productAccessoryProducts: 'Dodaci',
    productReplacementProducts: 'Dodatak',
    productAccessories: 'Dodatak',
    productReplacements: 'Zamjena',
    appProductDetailTabs: {
      description: 'Opis',
      parameters: 'Parametri',
      package: 'Sadržaj pakiranja',
      query: 'Upit',
      media: 'Foto i video',
      alternatives: 'Alternative',
      accessories: 'Dodaci',
      replacements: 'Dodatak',
      form: {
        enum: '!!!! ENUM VRIJEDNOST',
      },
    },
    cartSummaryPage: {
      title: 'Sažetak prije narudžbe',
      actions: {
        continueShopping: 'Nastavi s kupnjom',
        saveOrder: 'Naruči',
      },
      expectedDeliveryDate: {
        label: 'Traženi datum dostave',
        helperText: '',
        helperTextPreview: '',
      },
      noteForSeller: {
        label: 'Napomena za dobavljača',
        helperText: '',
        helperTextPreview: '',
      },
      paymentGateway: {
        label: 'Pristupnik za plaćanje',
        helperText: '',
        helperTextPreview: '',
      },
    },
    userProfile: {
      title: 'Korisnički profil',
      content: 'Rezultat',
      contactInfo: {
        newsletter: {
          title: 'Slanje upozorenja putem e-pošte',
          content: 'Želim dobivati upozorenja s web-stranice Digiexpert.hr',
        },
        title: 'Kontaktni podaci',
      },
      orders: {
        title: 'Povijest narudžbi',
      },
      invoices: {
        title: 'Moje fakture',
      },
      myOrders: 'Moje narudžbe',
      myInvoices: 'Moje fakture',
      logout: 'Odjavi se',
      login: 'Prijavi se',
      favorites: {
        title: 'Omiljeno',
      },
      changePassword: {
        title: 'Promijeni lozinku',
        fields: {
          login: 'Prijava (nije moguće promijeniti)',
          originalPassword: 'Prvotna lozinka',
          newPassword: 'Nova lozinka',
          confirmNewPassword: 'Potvrdi lozinku',
        },
        button: 'Spremi promjene',
      },
    },
    orderSavedThankPage: {
      title: 'Zahvaljujemo na vašoj narudžbi {orderNumber}',
      thankMessage: {
        template:
          'Provjerite svoju adresu e-pošte za potvrdu svoje narudžbe. E-poštu spremite za vlastite potrebe. U nastavku ćete pronaći informacije o svojoj narudžbi',
        phoneNumber: '',
        email: 'info@digiexpert.hr',
      },
      order: 'Broj narudžbe:',
      payment: 'Plaćanje dana:',
      cancelled: 'Stornirano',
      accepted: 'Primljeno',
      pending: 'U tijeku',
      paid: 'Plaćeno',
      actions: {
        back: 'Natrag na početnu stranicu',
        detail: 'Pojedinosti o narudžbi',
        continue: 'Nastavi s kupnjom',
      },
      decline: 'Odbijeno',
    },
    shoppingLists: {
      title: 'Popisi za kupnju',
      actions: {
        saveAsShoppingList: 'Spremi kao popis za kupnju',
        importShoppingListToCart: 'Import',
      },
    },
    searchResultPage: {
      search: 'Traženi izraz:',
      results: 'Rezultati pretraživanja',
    },
    homePageArticles: {
      title: 'Novosti',
      button: 'Prikaži sve članke',
    },
    loginPage: {
      title: 'Prijava',
      forgotPassword: 'Ponovno postavi lozinku',
      email: 'E-pošta',
      sendEmail: 'Pošalji e-poštu',
      info: 'Poslat ćemo vam e-poštu s vezom za promjenu lozinke',
      password: 'Lozinka',
      notSame: 'nije jednako',
      newPassword: 'Nova lozinka',
      confirmPassword: 'Potvrdi lozinku',
      changePassword: 'Promijeni lozinku',
    },
    productPrice: {
      withoutTax: 'bez PDV-a',
    },
    productDetailPage: {
      availability: {
        dateRange: [
          '{minDays, select,',
          '2 {Naručite proizvod još danas i bit će poslan u roku od {minDays}-{maxDays} dana.}',
          'other {Naručite proizvod još danas i bit će poslan u roku od {minDays}-{maxDays} dana.}',
          '}',
        ],
        today: 'Sve narudžbe zaprimljene do {time}, poslat ćemo vam isti dan.',
        todayWord: 'danas',
        tomorrow: 'Naručite {today}, narudžbu ćemo vam poslati već sutra ujutro.',
        nextDateTime: '13:30',
        dayOfWeek: {
          1: 'ponedjeljak',
          2: 'utorak',
          3: 'srijeda',
          4: 'četvrtak',
          5: 'petak',
          6: 'subota',
          0: 'nedjelja',
          message: 'naručite još danas {today}, narudžbu ćemo vam poslati već {day}.',
        },
        workingDay: 'Naručite do 13:30 h i šaljemo',
        afterWorkingDay: 'Naručite danas i šaljemo',
      },
      allDescription: 'KOMPLETNI OPIS PROIZVODA',
      demoProduct: {
        text: 'KUPITE JEFTINIJE za:',
      },
      productNote: {
        title: 'KUPITE JEFTINIJE za: X.XXX,00€',
        note: 'Napomena 2 – Ostali opis stanja ili slične informacije o proizvodu, zašto je na sniženju i što je s robom',
      },
      adornments: {
        notForSale: 'Ne prodaje se posebno',
        usedProduct: 'Sajam',
        freeGift: 'Poklon besplatno',
        freeShipping: 'Besplatna dostava',
        eol: 'Prodaja je završena',
        demo: 'Prezentacijski proizvod',
        sale: 'Popust',
      },
      disponibility: {
        primaryLessThan: 'NA SKLADIŠTU manje od {count} komada',
        primaryMoreThan: 'NA SKLADIŠTU {count, plural, zero {jedan komad} other {{count} i više komada}}',
        secondaryLessThan: 'NA SKLADIŠTU u pripremnom skladištu manje od {count} komada',
        secondaryMoreThan:
          'NA SKLADIŠTU u pripremnom skladištu {count, plural, zero {jedan komad} other {{count} i više komada}}',
        external: 'Proizvod je na putu do našeg skladišta',
        unavailable: 'Roba nije dostupna',
        showroom: '(izloženo u Shoowroomu)',
        soldout: 'Roba rasprodana',
        'on-way': {
          d: '{from, plural, one {Dostavit ćemo sutra} two {Dostavit ćemo prekosutra} few {Na putu dostavit ćemo za {from} dana} other {Na putu, dostavit ćemo za {from} dana}}',
          m: 'Na putu za {from, plural, one {minutu} two {minute} other {minute}}',
          M: 'Na putu za {from, plural, one {mjesec} two {mjeseca} few {mjeseca} other {mjeseci}}',
          dRange: 'Na putu za {to, plural, few {{from}-{to} dana} other {{from}-{to} dana}}',
          mRange: 'Na putu za {from}-{to, plural, one {dan} few {dana} other {dana}}',
          MRange: 'Na putu za {from}-{to, plural, one {mjesec} two {mjeseca} few {mjeseca} other {mjeseci}}',
          date: '{datum}',
        },
        time: 'Na putu za {count, plural, zero {jedan komad} other {{count} i više komada}}',
      },
    },
    comparePage: {
      title: 'Usporedi',
      removeButton: 'Ukloni proizvod',
      nothing: 'Nema ništa za usporedbu',
    },
    filter: {
      reset: 'Ponovno postavi filtar',
      search: 'Pretraži',
    },
    orderTermsAndConditions: {
      select: {
        label: 'Uvjeti narudžbe',
        helperText: '',
        helperTextPreview: '',
      },
    },
    orderDetailPage: {
      actions: {
        import: 'Dodaj u košaricu',
      },
    },
    invoiceBlogPreview: {
      title: 'Moje fakture',
      button: 'Sve fakture',
    },
    userProfilePage: {
      logout: 'Odjavi se',
      setting: 'Postavke',
      passwordChange: 'Promijeni lozinku',
    },
    appBarActionRegion: {
      changeRegion: 'Promijeni jezik',
      label: 'Dovrši',
    },
    subCategories: {
      title: 'Podkategorije',
    },
    aboutShoppingPage: {
      title: 'SVE O KUPNJI',
      contact: 'KONTAKT',
      electronicClosureOfRevenue: 'ELEKTRONIČKA EVIDENCIJA PRODAJE',
      informationDuty: 'OBAVEZNI PODATAK',
      processingOfPersonalData: 'OBRADA OSOBNIH PODATAKA',
      cashback: 'CASHBACK',
      delivery: 'DOSTAVA',
      availability: 'DOSTUPNOST',
      methodOfPayment: 'NAČIN PLAĆANJA',
      termsAndConditions: 'UVJETI',
      termsOfAppeal: 'UVJETI ŽALBE',
      contractwWithdrawal: 'OTKAZIVANJE UGOVORA',
    },
    customer: {
      viesValidation: {
        viesVerified: 'Porezni broj je potvrđen, cijene su obračunate bez PDV-a.',
        viesNotVerified: 'Vaš porezni broj nije potvrđen ili nemate pravo na kupnju bez PDV-a.',
      },
    },
    cartCustomerForm: {
      dataForDelivery: 'Podaci o dostavi',
      pleaseComplete: 'MOLIMO DOVRŠITE',
      closeButton: 'dovrši',
    },
    cartPromoCodes: {
      title: 'PRIMIJENI PROMO KOD',
      textFieldPlaceholder: 'PROMO KOD',
      textFieldHelperText: 'Moguće je primijeniti samo jedan promo kod po narudžbi',
      applyPromoCodeButton: 'PRIMIJENI',
    },
    cookiesBar: {
      agreeBtn: 'Slažem se',
      title: '',
      text: 'Web-stranica digiexpert.hr koristi kolačiće za poboljšanje usluga za naše kupce. Korištenjem ove web-stranice suglasni ste s uvjetima zaštite osobnih podataka i potvrđujete prihvaćanje kolačića.',
    },
    cartFormLogin: {
      btnSend: 'Pošalji',
      button: 'Prijavi se',
      title: 'prijava',
      forgottenPasswordBtn: 'Zaboravili ste lozinku?',
      text1: 'Na navedenu adresu e-pošte poslat ćemo vam jednokratnu lozinku.',
      text2: 'Nakon prijave unesite novu lozinku.',
      error: 'Neispravno korisničko ime ili lozinka.',
    },
    cartSummartSection: {
      title: 'SAŽETAK NARUDŽBE',
    },
    appCartSummarySubjectsList: {
      title: 'Slika',
      text: 'Artikl',
    },
    newsletterText: {
      text1: 'Je li vam potreban savjet?',
      text2: 'Možete nas nazvati svaki radni dan od 08:00 do 16:00 sati.',
    },
    cartSummarySection: {
      labelWithoutVAT: '(bez PDV-a)',
      priceLabel: 'CIJENA/NAKNADE',
      amount: 'Količina',
      totalPriceLabel: 'UKUPNA CIJENA',
      totalPrice: 'Ukupno',
      submitButton: 'Pošalji narudžbu',
    },
    cartSummarySubjectsListItemDirectPreview: {
      amountTitle: 'komada',
    },
    cartSummaryListItem: {
      freeTitle: '- besplatno',
      amountTitle: 'komad',
    },
    sxCartNotes: {
      note: 'Ne navodi datum dostave',
      addNote: 'Dodajte napomenu',
    },
    productDetail: {
      promoCode: {
        title: 's promo kodom:',
        validTo: 'vrijedi do:',
      },
      productSendLink: {
        copyLinkBtn: 'kopiraj vezu',
        sendLinkByEmail: 'Pošalji vezu e-poštom',
      },
      productDetailBarInformation: {
        addToFavourites: 'Dodaj u omiljeno',
        compareProduct: 'Usporedi proizvode',
        sendLink: 'Pošalji vezu',
        serviceList: 'popis usluga',
        questions: 'Pitanja',
      },
    },
    cartFormShipping: {
      checkbox: {
        invoiceToCompany: 'Kupujem na tvrtku',
        otherDeliveryInfo: 'Adresa za dostavu se razlikuje',
        otherDeliveryInfoAlert: 'Platiša PDV-a ne može koristiti drugačiju adresu za dostavu.',
      },
      companyNameLabel: 'Ime tvrtke',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Prezime',
      streetLabel: 'Ulica',
      cityLabel: 'Grad',
      zipLabel: 'Poštanski broj',
      companyIDLabel: 'Porezni broj',
      countryLabel: 'Država',
    },
    cartFormPersonal: {
      label: 'Adresa za dostavu',
      firstNameLabel: 'Ime',
      lastNameLabel: 'Prezime',
      streetLabel: 'Ulica, broj',
      pscLabel: 'Poštanski broj',
      cityLabel: 'Grad',
      countryLabel: 'Država',
      phoneLabel: 'Telefonski broj',
      emailLabel: 'adresa e-pošte',
      passwordLabel: 'Lozinka',
      controlPasswordLabel: 'Kontrolna lozinka',
    },
    cartFormInvoice: {
      labelDesktop: 'Kontaktni podaci/adresa za fakturu',
      labelMobile: 'kontaktni podaci',
      companyLabel: 'Ime tvrtke',
      password: 'Lozinka',
      confirmPassword: 'Provjera lozinke',
      email: 'E-pošta',
      firstName: 'Ime',
      lastName: 'Prezime',
      countryLabel: 'Država',
      phone: 'Telefonski broj',
      phoneError: 'Telefonski broj nije ispravan',
      streetLabel: 'Ulica',
      streetNumberLabel: 'broj',
      zipLabel: 'Poštanski broj',
      icoLabel: 'IR',
      dicLabel: 'OIB ( R1 račun )',
      country: 'Zemlja',
      cityLabel: 'Grad',
      registration: 'Želim otvoriti račun',
      registrationNote: 'i možete pratiti svoje narudžbe, pošiljke i plaćanja ...',
      button: 'Spremi promjene',
      label: 'Adresa za fakturu',
    },
    page404: {
      title: 'Žao nam je, stranica ne postoji.',
      subTitle: 'Idite na početnu stranicu.',
      button: 'Vratite se na početnu stranicu.',
    },
    productListOrderSelectorButton: {
      selectLabel: 'Razvrstano prema:',
      byName: 'Ime',
      byPrice: 'Cijena',
      onlyStock: 'Samo na skladištu',
      resetFilter: 'otkaži',
      displayCountOfProducts: 'Proizvodi:',
      search: 'Pretraživanje',
      clear: 'Izbriši',
      ok: 'U redu',
    },
    priceRangeSliderField: {
      title: 'Raspon cijena',
    },
    productListMultipleFilterMobileDialog: {
      headerTitle: 'Filtriraj prema',
    },
    productListMultipleFilterMobile: {
      title: 'Filtriraj',
    },
    cartCompany: {
      companyName: 'Ime tvrtke',
      regNo: 'MB',
      taxNo: 'OIB ( R1 račun )',
      taxNoPlaceholder: 'HR00000000000',
      warningMessage: {
        warning: 'Važno.',
        message: 'U slučaju dostave na adresu drugačiju od adrese društva na narudžbu će biti obračunat PDV.',
      },
    },
    searchResultsPage: {
      match: 'Za {searchText} smo pronašli {resultCountFormatted} rezultata.',
      nomatch: 'Nismo pronašli nijedan rezultat za {searchText}.',
      matchingCategories: 'Pronađene kategorije',
      button: 'Vratite se na početnu stranicu.',
    },
    selectMultipleFilter: {
      totalChipsLabel: 'Odabrano je {total} proizvoda',
    },
    cartDelivery: {
      firstName: 'Ime',
      lastName: 'Prezime',
      companyName: 'Ime tvrtke',
      streetLabel: 'Ulica',
      streetNumberLabel: 'broj',
      zipLabel: 'Poštanski broj',
      country: 'Zemlja',
      cityLabel: 'Grad',
      street: 'Ulica, broj',
      zip: 'Poštanski broj',
      city: 'Grad',
    },
    cartNotes: {
      shippingLabel: 'Dopuni napomenu za dostavljača',
      shippingNote: 'Napomena za dostavljača',
      sellerLabel: 'Dopuni napomenu za prodavatelja',
      sellerNote: 'Napomena za prodavatelja',
      loginNote: 'Ako kod nas već imate račun, nakon registracije ćemo vam sve ispuniti.',
    },
    cartCountryShipping: {
      title: 'Zemlja dostave',
      label: 'Odabir zemlje utječe na način plaćanja, dostavu i datum dostave',
    },
    cartAddress: {
      bllingAddress: 'Adresa za fakturu',
      deliveryAddress: 'Adresa za dostavu',
      pickUpPointAddress: 'Adresa mjesta preuzimanja',
    },
    cartAgreements: {
      customerVerified:
        'Ne slažem se sa slanjem upitnika o zadovoljstvu u okviru programa „Potvrdili kupci” koji pomaže u poboljšanju naših usluga.',
    },
    loginDialog: {
      username: 'korisničko ime',
      password: 'Lozinka',
    },
    sprinxFormsValidation: {
      confirmPassword: 'Unesene lozinke nisu jednake',
      invalidPostalCode: 'Uneseni PB nije u valjanom obliku',
      usernameIsNotFree: 'Korisnik s tim imenom već je registriran.',
      emailEmpty: 'Polje je obvezno!',
    },
    invoices: {
      actions: {
        addNewInvoice: 'Dodaj novu fakturu',
        all: 'Sve fakture',
      },
      listPage: {
        table: {
          columns: {
            downloadLink: 'PDF',
            number: 'Broj',
            invoiceDate: 'Datum fakture',
            dueDate: 'Do datuma',
            total: 'Bez PDV-a',
            totalWithTax: 'Ukupno',
            billingAddress: 'Adresa za fakturu',
            orderNumber: 'Redni broj',
            unpaidAmount: 'Neplaćeni dio',
          },
          filter: {
            number: 'Broj',
          },
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Pregled faktura',
          columns: {
            priceUnit: 'Cijena/jedinica',
          },
        },
      },
    },
    cartSummaryTotal: {
      totalPrice: '',
      totalPriceWithTax: 'Ukupna cijena narudžbe',
      tax: 'PDV',
      withoutTax: 'Ukupna cijena bez PDV-a',
    },
    compare: {
      addToCompare: 'Proizvod je dodan u uspoređivač',
      removeToCompare: 'Proizvod je uklonjen iz polja za usporedbu',
      price: 'Cijena',
      ean: 'EAN',
    },
    favorite: {
      addToFavorite: 'Proizvod je dodan u omiljene',
      removeFromFavorite: 'Proizvod je uklonjen iz omiljenih',
    },
    registration: {
      important: 'VAŽNO!',
      note: 'Poslat ćemo vam jednokratnu lozinku putem e-pošte.',
      gdprAgreement: 'Morate biti suglasni s obradom osobnih podataka',
      submitButton: 'Registriraj se',
      fail: 'Registracija nije bila uspješna, pokušajte kasnije',
      success: 'registracija je bila uspješna',
      title: 'Registracija',
      dialog: 'Ako kod nas još uvijek nemate račun, možete se registrirati',
      here: 'ovdje',
    },
    appBarActionGlobalSearchComposed: {
      label: 'Traži proizvode',
    },
    appBarActions: {
      about: 'Sve o kupnji',
      aboutHref: '/o-kupnji',
      compare: 'Usporedi',
    },
    appBarActionUser: {
      title: 'Prijava',
    },
    footer: {
      copyright: 'COPYRIGHT ©️ ČESKÝ VELKOOBCHOD S.R.O. SVA PRAVA PRIDRŽANA',
    },
    appArticlesPage: {
      title: 'Tekstovi ',
    },
    errorPage: {
      title: 'Nešto nije u redu.',
      button: 'Početna stranica',
    },
    cartSummary: {
      withoutTax: 'bez PDV-a',
    },
    renewPasswordForm: {
      success: 'Na vašu adresu e-pošte poslana je nova lozinka',
      emailNotFound: 'Email nije pronađen!',
      error: 'Nešto nije u redu.',
    },
    cartSubjectListItemQuantityWarning: 'Aktualni je broj proizvoda na skladištu {count}',
  },
};

export default messagesHR;
