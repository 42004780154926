import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { pickHTMLProps } from 'pick-react-known-prop';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    tagContainer: {
      padding: theme.spacing(0, 1),
      '& img': {
        width: 90,
        height: 90,
        margin: theme.spacing(0, 1.5),
        [theme.breakpoints.down('md')]: { width: 70, height: 70, margin: theme.spacing(0, 1.25) },
      },
    },
  }),
  { name: 'ProductDetailCertificate' },
);

function ProductDetailCertificate({ classes: pClasses, className, ...rest }) {
  const classes = useStyles({ classes: pClasses });

  React.useEffect(() => {
    const smdObject = {
      Key: 'Cro_3370',
      Type: 'badge',
      Version: 2,
      BadgeClassName: 'smdWrapperTag',
    };
    const smdWrapper = document.createElement('script');
    smdWrapper.id = '_smdWrapperTag';
    smdWrapper.type = 'text/javascript';
    smdWrapper.src = `https://cpx.smind.hr/Log/LogData?data=${JSON.stringify(smdObject)}`;
    smdWrapper.async = true;
    document.body.insertBefore(smdWrapper, document.body.firstChild);

    return () => {
      const el = document.getElementById('_smdWrapperTag');
      document.body.removeChild(el);
    };
  }, []);

  return (
    <div {...pickHTMLProps(rest)} className={clsx(classes.root, className)}>
      <div className={clsx('smdWrapperTag', classes.tagContainer)} />
    </div>
  );
}

ProductDetailCertificate.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    tagContainer: PropTypes.string,
  }),
  className: PropTypes.string,
};

ProductDetailCertificate.defaultProps = {
  classes: undefined,
  className: undefined,
};

export default ProductDetailCertificate;
